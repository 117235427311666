import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "pages/en/SloganFeature.js";
import MainFeature from "pages/en/About.js";
import FeatureStats from "pages/en/LupiHeader.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "pages/en/Reach.js";
import SMFeature from "pages/en/SocialMediaManagement";
import GDFeature from "pages/en/GameDevelopment.js";
import Companies from "./en/Companies";
import Services from "pages/en/Services.js";
// import Title from "../HeaderEn.js";
import Title from "pages/en/Header";
import Footer from "./en/FooterDark";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
const HighlightedText = tw.span`text-yellow-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Title />

      {/* <Routes>
    <Route path="/" element= {<Services />}>
      <Route path="services" element = {<Services />} />
     <Route path="companies" element = {<Companies />} />
      </Route>
    
    <Route path="*" element={<Navigate to="/xyz" />} />
  </Routes> */}

      <Features
        heading={
          <>
            in a <HighlightedText>holistic</HighlightedText> way
          </>
        }
      />

      <FeatureStats />

      <SMFeature
        heading={"Social Media Management?"}
        subheading={"Interested in"}
        description={
          "Pluto Socio is our social media management company. It offers 4 different plans for clients such as: e-commerce companies, exclusive content seeking companies, personal accounts, casual content seeking professionals."
        }
      />
      <GDFeature />
      <Services />
      <MainFeature />

      <GetStarted />

      <Footer />
    </AnimationRevealPage>
  );
};
